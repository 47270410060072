export function getReportedMessage(err) {
  let message;
  if (err.data) {
    message = err.data.message;
  } else if (err.error) {
    message = err.error.message;
  } else {
    console.error(err);
    message = "Transaction failed";
  }
  message = message.replace("execution reverted: ", "");
  return message;
}

// export function handleRPCError(
//   err,
//   updateCountersCallback,
//   retryCallback,
//   retryTimeout = 3000
// ) {
//   if (err.data && err.data.message && err.data.message.includes("No nodes")) {
//     updateCountersCallback();
//     return;
//   }
//
//   if (retryCallback) {
//     setTimeout(() => {
//       updateCountersCallback();
//       retryCallback(updateCountersCallback);
//     }, retryTimeout);
//   }
// }
