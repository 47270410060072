import { ethers } from "ethers";
import { createToast } from "@/plugins/toastNotificationsPlugin";
import { publicProvider } from "@/utils/blockchain";
import BigNumberJs from "bignumber.js";
import { getReportedMessage } from "@/utils/errorHandling";
import smartContracts from "@/utils/SmartContracts";

export interface TokenInfo {
  address: string;
  decimals: number;
  // name: string;
  symbol: string;
}

export interface TokenInfoAmount {
  tokenInfo: TokenInfo;
  amount: ethers.BigNumberish;
}

const tokensMap: Map<string, TokenInfo> = new Map();
const smartContractsMap: Map<string, ethers.Contract> = new Map();

export async function getTokenInfo(
  address: string
): Promise<TokenInfo | undefined> {
  try {
    const addressLC = address.toLowerCase();
    if (tokensMap.has(addressLC)) {
      return tokensMap.get(addressLC);
    }

    const contract = getTokenSmartContract(addressLC);

    if (!contract) {
      return undefined;
    }
    const decimals = await contract.decimals();
    const tokenInfo: TokenInfo = {
      address: addressLC,
      decimals: typeof decimals === "number" ? decimals : decimals.toNumber(),
      // name: await contract.name(),
      symbol: await contract.symbol(),
    };
    tokensMap.set(addressLC, tokenInfo);

    return tokenInfo;
  } catch (err) {
    if (err.code === "CALL_EXCEPTION") {
      createToast({
        type: "error",
        text: "Could not fetch token from the blockchain. Please ensure your entered address correctly and your metamask is on the right chain.",
      });
    } else if (err.code === "UNSUPPORTED_OPERATION") {
      createToast({
        type: "error",
        text: "You are probably not connected to MetaMask. Please establish the connection to MetaMask and refresh the page.",
      });
    } else {
      createToast({
        type: "error",
        text: getReportedMessage(err),
      });
    }
  }
}

export function getTokenSmartContract(
  address: string,
  ABI: any = smartContracts.erc20.ABI
) {
  try {
    const addressLC = address.toLowerCase();
    if (smartContractsMap.has(addressLC)) {
      return smartContractsMap.get(addressLC)!;
    }

    const tokenContract = new ethers.Contract(addressLC, ABI, publicProvider);
    smartContractsMap.set(addressLC, tokenContract);
    return tokenContract;
  } catch (err) {
    createToast({
      type: "error",
      text: getReportedMessage(err),
    });
  }
}

export function calculatePriceOfPair(
  token1: TokenInfoAmount,
  token2: TokenInfoAmount,
  divideByToken: string
): BigNumberJs {
  const divideByTokenLC = divideByToken.toLowerCase();
  if (
    token1.tokenInfo.address !== divideByTokenLC &&
    token2.tokenInfo.address !== divideByTokenLC
  ) {
    throw new Error("Invalid arguments passed to calculatePriceOfPair");
  }

  const bigNumber1 = new BigNumberJs(
    ethers.utils
      .parseUnits(token1.amount.toString(), 18 - token1.tokenInfo.decimals)
      .toString()
  );
  const bigNumber2 = new BigNumberJs(
    ethers.utils
      .parseUnits(token2.amount.toString(), 18 - token2.tokenInfo.decimals)
      .toString()
  );

  let result;
  if (token1.tokenInfo.address === divideByTokenLC) {
    result = bigNumber2.div(bigNumber1);
  } else if (token2.tokenInfo.address === divideByTokenLC) {
    result = bigNumber1.div(bigNumber2);
  }
  return result;
}

export async function clearTokensMap() {
  tokensMap.clear();
  smartContractsMap.clear();
}
