<template>
  <svg viewBox="0 0 250 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4577 34.6862C26.9729 34.6862 34.6864 26.9726 34.6864 17.4575C34.6864 7.94231 26.9729 0.22876 17.4577 0.22876C7.94256 0.22876 0.229004 7.94231 0.229004 17.4575C0.229004 26.9726 7.94256 34.6862 17.4577 34.6862Z"
      fill="#0A0B0B"
    ></path>
    <path
      d="M20.559 19.9047L20.6437 19.8509C22.9845 18.3602 24.4009 15.778 24.4009 13.0036V9.29907H22.012V13.4006C22.012 15.2449 21.1094 16.9713 19.5946 18.0238L19.3566 18.1886L13.1248 9.29907H10.3516L14.4005 15.0687C12.0734 16.3603 10.6227 18.8052 10.6044 21.4664L10.5747 25.616H12.9029L12.9624 21.3153C12.9887 19.4391 14.0058 17.7172 15.6361 16.7894L21.7901 25.616H24.5633L20.559 19.9047Z"
      fill="#D7B567"
    ></path>
    <path
      d="M17.4575 34.9151C7.83123 34.9151 0 27.0838 0 17.4575C0 7.83123 7.83123 0 17.4575 0C27.0838 0 34.9151 7.83123 34.9151 17.4575C34.9151 27.0838 27.0838 34.9151 17.4575 34.9151ZM17.4575 0.457632C8.08407 0.457632 0.457632 8.08407 0.457632 17.4575C0.457632 26.831 8.08407 34.4574 17.4575 34.4574C26.831 34.4574 34.4574 26.831 34.4574 17.4575C34.4574 8.08407 26.831 0.457632 17.4575 0.457632Z"
      fill="#D7B567"
    ></path>
    <path
      d="M47.2998 4.57178H45.8457V30.7381H47.2998V4.57178Z"
      fill="#D7B567"
    ></path>
    <path
      d="M69.3106 21.3063H61.9862L60.5893 24.687H56.8481L63.8797 8.91016H67.4858L74.5402 24.687H70.7087L69.3106 21.3063ZM68.162 18.5342L65.6599 12.4934L63.1578 18.5342H68.162Z"
      fill="#D7B567"
    ></path>
    <path
      d="M76.1855 8.91016H79.8363V21.7124H87.7476V24.687H76.1855V8.91016Z"
      fill="#D7B567"
    ></path>
    <path
      d="M102.014 21.757V24.687H89.7988V8.91016H101.721V11.8401H93.4267V15.2655H100.751V18.1051H93.4267V21.7559H102.014V21.757Z"
      fill="#D7B567"
    ></path>
    <path
      d="M115.391 9.60911C116.419 10.0748 117.212 10.736 117.768 11.5929C118.324 12.4499 118.602 13.4635 118.602 14.6362C118.602 15.7929 118.324 16.8042 117.768 17.668C117.212 18.5318 116.419 19.1931 115.391 19.6518C114.361 20.1106 113.148 20.3394 111.75 20.3394H108.572V24.6892H104.921V8.91237H111.75C113.147 8.91008 114.361 9.14347 115.391 9.60911ZM114.049 16.6521C114.62 16.1784 114.906 15.5069 114.906 14.6351C114.906 13.7484 114.62 13.0688 114.049 12.5952C113.478 12.1215 112.644 11.8847 111.547 11.8847H108.572V17.3614H111.547C112.644 17.3625 113.478 17.1257 114.049 16.6521Z"
      fill="#D7B567"
    ></path>
    <path
      d="M135.664 8.91016V24.687H132.013V18.2184H124.846V24.687H121.195V8.91016H124.846V15.1305H132.013V8.91016H135.664Z"
      fill="#D7B567"
    ></path>
    <path
      d="M149.434 11.8401V16.0092H156.737V18.9392H149.434V24.687H145.783V8.91016H157.706V11.8401H149.434Z"
      fill="#D7B567"
    ></path>
    <path
      d="M160.185 8.91016H163.836V24.687H160.185V8.91016Z"
      fill="#D7B567"
    ></path>
    <path
      d="M182.047 8.91016V24.687H179.05L171.184 15.1076V24.687H167.578V8.91016H170.598L178.442 18.4895V8.91016H182.047Z"
      fill="#D7B567"
    ></path>
    <path
      d="M196.179 21.3063H188.853L187.456 24.687H183.715L190.747 8.91016H194.353L201.407 24.687H197.576L196.179 21.3063ZM195.029 18.5342L192.527 12.4934L190.025 18.5342H195.029Z"
      fill="#D7B567"
    ></path>
    <path
      d="M217.522 8.91016V24.687H214.524L206.659 15.1076V24.687H203.053V8.91016H206.073L213.917 18.4895V8.91016H217.522Z"
      fill="#D7B567"
    ></path>
    <path
      d="M224.452 23.9101C223.153 23.2111 222.131 22.2421 221.387 21.003C220.644 19.764 220.272 18.3625 220.272 16.7997C220.272 15.2369 220.644 13.8354 221.387 12.5963C222.131 11.3573 223.153 10.3871 224.452 9.68922C225.752 8.99018 227.213 8.64124 228.837 8.64124C230.204 8.64124 231.439 8.88148 232.545 9.362C233.649 9.84251 234.576 10.5336 235.328 11.4351L232.984 13.5985C231.916 12.3664 230.595 11.7508 229.017 11.7508C228.04 11.7508 227.17 11.9648 226.403 12.3927C225.637 12.8206 225.039 13.4178 224.611 14.1843C224.184 14.9508 223.97 15.8226 223.97 16.7985C223.97 17.7756 224.184 18.6462 224.611 19.4128C225.039 20.1793 225.637 20.7765 226.403 21.2044C227.17 21.6323 228.04 21.8462 229.017 21.8462C230.595 21.8462 231.916 21.2227 232.984 19.9756L235.328 22.1391C234.576 23.0555 233.645 23.7545 232.533 24.2351C231.421 24.7156 230.181 24.9558 228.815 24.9558C227.206 24.9581 225.752 24.608 224.452 23.9101Z"
      fill="#D7B567"
    ></path>
    <path
      d="M250 21.757V24.687H237.785V8.91016H249.707V11.8401H241.413V15.2655H248.737V18.1051H241.413V21.7559H250V21.757Z"
      fill="#D7B567"
    ></path>
    <path
      d="M17.4575 33.2241C8.76362 33.2241 1.69092 26.1514 1.69092 17.4575C1.69092 8.76362 8.76362 1.69092 17.4575 1.69092C26.1514 1.69092 33.2241 8.76362 33.2241 17.4575C33.2241 26.1514 26.1514 33.2241 17.4575 33.2241ZM17.4575 2.03414C8.95354 2.03414 2.03414 8.95354 2.03414 17.4575C2.03414 25.9614 8.95354 32.8809 17.4575 32.8809C25.9614 32.8809 32.8809 25.9614 32.8809 17.4575C32.8809 8.95354 25.9614 2.03414 17.4575 2.03414Z"
      fill="black"
    ></path>
  </svg>
</template>
