import { defineStore } from "pinia";
import { SwitchEthereumChainParameter } from "../../types";
import { ethers } from "ethers";
import { createToast } from "@/plugins/toastNotificationsPlugin";
import { avaxChainId } from "@/utils/blockchain";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getReportedMessage } from "@/utils/errorHandling";

declare global {
  interface Window {
    ethereum: any;
  }
}

export interface WalletStoreState {
  chainId: number | undefined;
  address: string;
  isMetamask: undefined | boolean;
  isCoinbase: undefined | boolean;
  isWalletConnected: boolean;
}

enum WalletType {
  METAMASK = "metamask",
  WALLET_CONNECT = "wallet_connect",
  COINBASE = "coinbase",
}

// NOTE: these variables are placed intentionally outside pinia store management, as these objects don't like proxies and don't work properly
let wcProvider: undefined | WalletConnectProvider;
let walletProvider: any;
export let provider: undefined | ethers.providers.Web3Provider;
export let signer: undefined | ethers.Signer;

/**
 * Find specific provider, mark as selected and return ethereum object.
 * In case user has multiple wallet extension (e.g. MetaMask and Coinbase), window.ethereum store all providers
 * It is important to select one you need to be sure you are using right provider.
 * @param type
 */
function findProvider(type: WalletType) {
  const ethereum = window.ethereum as any;
  if (!ethereum) {
    return;
  }
  let selectedProvider: any;
  for (const provider of ethereum.providers || [ethereum]) {
    if (
      (type === WalletType.METAMASK && provider.isMetaMask) ||
      (type === WalletType.COINBASE && provider.isCoinbaseWallet)
    ) {
      selectedProvider = provider;
    }
  }

  if (selectedProvider) {
    if ("setSelectedProvider" in ethereum) {
      ethereum.setSelectedProvider(selectedProvider);
    }
    return selectedProvider;
  }
}

// region address listeners
export interface AddressListener {
  init: (address: string) => void;
  reset: () => void;
}

const addressChangeListeners: Array<AddressListener> = [];
// endregion

// the first argument is a unique id of the store across your application
export const useWalletStore = defineStore("wallet", {
  state(): WalletStoreState {
    return {
      chainId: undefined,
      address: "",
      isMetamask: undefined,
      isCoinbase: undefined,
      isWalletConnected: false,
    };
  },
  getters: {
    isAvalanche(): boolean {
      return this.chainId === avaxChainId;
    },
  },
  actions: {
    addAddressListener(callbacks: AddressListener) {
      if (addressChangeListeners.indexOf(callbacks) === -1) {
        addressChangeListeners.push(callbacks);
        if (this.isAvalanche && this.address) {
          callbacks.init(this.address);
        }
      }
    },
    removeAddressListener(callbacks: AddressListener) {
      const indexOf = addressChangeListeners.indexOf(callbacks);
      if (indexOf !== -1) {
        addressChangeListeners.splice(indexOf, 1);
      }
    },
    setChainId(chainId: number) {
      this.chainId = chainId;
      if (this.isAvalanche) {
        if (this.address && !this.isWalletConnected) {
          this.isWalletConnected = true;
          addressChangeListeners.forEach((callbacks) =>
            callbacks.init(this.address)
          );
        }
      } else {
        if (this.isWalletConnected) {
          this.isWalletConnected = false;
          addressChangeListeners.forEach((callbacks) => callbacks.reset());
        }
      }
    },
    tryToRestoreConnection() {
      const usedProvider = localStorage.getItem("usedProvider");
      if (usedProvider === WalletType.METAMASK) {
        this.initMetamask();
      } else if (usedProvider === WalletType.WALLET_CONNECT) {
        this.initWalletConnect();
      } else if (usedProvider === WalletType.COINBASE) {
        this.initCoinbase();
      }
    },
    async initMetamask(suggestNetworkChange = false) {
      walletProvider = findProvider(WalletType.METAMASK);
      if (walletProvider) {
        provider = new ethers.providers.Web3Provider(walletProvider);
        signer = provider.getSigner();
        this.isMetamask = true;

        await provider.send("eth_requestAccounts", []);

        this.initEventHandlers();
        await this.fetchAccounts();

        if (suggestNetworkChange && !this.isAvalanche) {
          this.suggestSwitchChain(avaxChainId);
        }

        localStorage.setItem("usedProvider", WalletType.METAMASK);
      } else {
        createToast({
          type: "warning",
          text: "Seems like Metamask is not installed.",
        });
      }
    },
    async initWalletConnect() {
      wcProvider = new WalletConnectProvider({
        chainId: avaxChainId,
        rpc: {
          [avaxChainId]: process.env.VUE_APP_PUBLIC_RPC!,
        },
      });
      walletProvider = wcProvider;

      //  Enable session (triggers QR Code modal)
      await wcProvider.enable();

      provider = new ethers.providers.Web3Provider(wcProvider);
      signer = provider.getSigner();

      this.initEventHandlers();

      wcProvider.on("disconnect", (code: number, reason: string) => {
        this.disconnect();
        console.log(code, reason);
      });

      this.setChainId(wcProvider.chainId);

      await this.fetchAccounts();

      localStorage.setItem("usedProvider", WalletType.WALLET_CONNECT);
    },
    async initCoinbase(suggestNetworkChange = false) {
      walletProvider = findProvider(WalletType.COINBASE);
      if (walletProvider) {
        provider = new ethers.providers.Web3Provider(walletProvider as any);
        signer = provider.getSigner();
        this.isCoinbase = true;

        await provider.send("eth_requestAccounts", []);

        this.initEventHandlers();
        await this.fetchAccounts();

        if (suggestNetworkChange && !this.isAvalanche) {
          this.suggestSwitchChain(avaxChainId);
        }

        localStorage.setItem("usedProvider", WalletType.COINBASE);
      } else {
        createToast({
          type: "warning",
          text: "Seems like Coinbase Wallet is not installed.",
        });
      }
    },
    initEventHandlers() {
      if (!walletProvider || !provider) {
        console.error("walletProvider or provider is missing");
        return;
      }

      walletProvider.on("accountsChanged", (accounts: string[]) => {
        this.setAddress(accounts.length > 0 ? accounts[0] : "");
      });

      walletProvider.on("chainChanged", async () => {
        await this.disconnect(false);
        this.tryToRestoreConnection();
      });

      provider.on("network", (newNetwork) => {
        this.setChainId(newNetwork.chainId);
      });
    },
    async disconnect(cleanUsedProvider = true) {
      if (walletProvider) {
        walletProvider.removeAllListeners();
        walletProvider = undefined;
      }
      if (wcProvider && wcProvider.connected) {
        await wcProvider.disconnect();
        wcProvider = undefined;
      }

      if (cleanUsedProvider) {
        localStorage.removeItem("usedProvider");
      }
      provider = undefined;
      signer = undefined;
      this.isMetamask = undefined;
      this.isCoinbase = undefined;

      this.address = "";
      this.isWalletConnected = false;
      addressChangeListeners.forEach((callbacks) => callbacks.reset());
    },
    async fetchAccounts() {
      if (!provider) {
        console.error("Provider is not initialised!");
        return;
      }

      const accounts = await provider.listAccounts();
      if (accounts.length > 0) {
        this.setAddress(accounts[0]);
      }
    },
    async setAddress(account: string) {
      this.address = account.toLowerCase();

      const debugAddress = localStorage.getItem("debugAddress");
      if (debugAddress) {
        this.address = debugAddress;
      }

      if (this.address && this.isAvalanche) {
        if (!this.isWalletConnected) {
          this.isWalletConnected = true;
        }
        addressChangeListeners.forEach((callbacks) =>
          callbacks.init(this.address)
        );
      }
    },
    async suggestSwitchChain(toChainId: number) {
      if (!provider) {
        console.error("Provider is not initialised!");
        return;
      }

      try {
        await provider.send("wallet_switchEthereumChain", [
          {
            chainId: ethers.utils.hexValue(toChainId),
          } as SwitchEthereumChainParameter,
        ]);
      } catch (err: any) {
        if (err.message.startsWith("Unrecognized chain ID")) {
          // if (this.isMetamask) {
          //   await provider.send("wallet_addEthereumChain", [addTestnetParams]);
          // } else {
          createToast({
            type: "error",
            text: `Chain ID ${toChainId} is not supported by your wallet. Please add it first, and try again.`,
          });
          // }
        } else {
          createToast({
            type: "error",
            text: getReportedMessage(err),
          });
        }
      }
    },
    async addToken(address, symbol, decimals, image) {
      if (!walletProvider && !this.isMetamask && !this.isCoinbase) {
        throw new Error("walletProvider is not initialised!");
      }

      await walletProvider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: address, // The address that the token is at.
            symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: decimals, // The number of decimals in the token
            image: image, // A string url of the token logo
          },
        },
      });
    },
  },
});
