import { defineStore } from "pinia";
import { ethers } from "ethers";
import { getTokenInfo, getTokenSmartContract } from "@/utils/tokenCache";
import { signer, useWalletStore } from "@/store/wallet";
import {
  fetchTokenBalance,
  makeCallWithPendingToast,
} from "@/utils/blockchain";
import smartContracts from "@/utils/SmartContracts";

export interface ApproveState {
  approvedAmounts: { [key: string]: ethers.BigNumber };
  balances: { [key: string]: ethers.BigNumber };
}

export const useBalanceStore = defineStore("balance", {
  state(): ApproveState {
    return {
      approvedAmounts: {},
      balances: {
        [smartContracts.erc20.USDC]: ethers.BigNumber.from(0),
        [smartContracts.nAleph.address]: ethers.BigNumber.from(0),
      },
    };
  },
  getters: {
    wallet() {
      return useWalletStore();
    },
  },
  actions: {
    init() {
      this.fetchBalance(smartContracts.erc20.USDC);
      this.fetchBalance(smartContracts.nAleph.address);
    },
    reset() {
      Object.keys(this.balances).forEach((token) => {
        this.balances[token] = ethers.BigNumber.from(0);
      });
    },
    getAllowanceKey(token: string, spender: string) {
      return token + spender;
    },
    async fetchAllowance(token: string, spender: string) {
      let allowance = ethers.BigNumber.from(0);
      const tokenContract = getTokenSmartContract(token);

      if (this.wallet.address && tokenContract) {
        allowance = await tokenContract.allowance(this.wallet.address, spender);
      }

      this.approvedAmounts[this.getAllowanceKey(token, spender)] = allowance;
    },
    async fetchBalance(token: string, shouldChange = false) {
      this.balances[token] = await fetchTokenBalance(
        token,
        shouldChange ? this.balances[token] : undefined
      );
    },

    async approveToken(
      tokenAddress: string,
      spenderAddress: string,
      amount?: ethers.BigNumber
    ): Promise<void> {
      if (!signer) {
        return;
      }
      const tokenInfo = await getTokenInfo(tokenAddress);
      if (!tokenInfo) {
        throw new Error("Can't fetch token contract");
      }

      return new Promise((resolve, reject) => {
        makeCallWithPendingToast({
          callTx: async () => {
            const tokenSmartContract = getTokenSmartContract(
              tokenAddress
            )?.connect(signer!);

            if (!amount || amount.eq(0)) {
              amount = ethers.BigNumber.from(
                "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
              );
            }
            return tokenSmartContract?.approve(spenderAddress, amount);
          },
          waitingToastText: `Approval for ${tokenInfo.symbol} sent. Awaiting confirmation...`,
          successToastText: `${tokenInfo.symbol} was successfully approved.`,
          onSuccess: async () => {
            await this.fetchAllowance(tokenAddress, spenderAddress);
            resolve();
          },
          onError: () => reject(),
        });
      });
    },
  },
});
