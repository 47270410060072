import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import "./assets/style.scss";
import toastNotificationsPlugin from "./plugins/toastNotificationsPlugin";

const app = createApp(App);

app.use(toastNotificationsPlugin).use(router).use(createPinia()).mount("#app");
