<template>
  <div>
    <div
      class="app-container text-center text-slate-400 text-sm tracking-wide py-4 flex items-center justify-between mb-16 lg:mb-0 flex-col md:flex-row"
    >
      <div class="flex items-center space-x-6">
        <a
          href="https://aleph.finance"
          class="hover:text-slate-100"
          target="_blank"
          >Aleph Finance</a
        >
      </div>
      <div class="mt-2 md:mt-0">With love and care from LorDeCry</div>
    </div>
  </div>
</template>
